
import { Options, Vue } from "vue-class-component";
import LeftStepBar from "@/shared/components/LeftStepBar.vue";
import SectionTitle from "@/shared/components/SectionTitle.vue";
import LoginForm from "@/components/forms/LoginForm.vue";

@Options({
  components: {
    SectionTitle,
    LoginForm,
    LeftStepBar,
  },
  mounted() {
    localStorage.removeItem("LeadToken");
  }

})
export default class Login extends Vue {}
