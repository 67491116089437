
import useVuelidate from "@vuelidate/core";
import { required } from "@/utils/i18n-validators";
import {
  email,
  helpers,
  sameAs,
  minLength,
  maxLength,
  alphaNum,
} from "@vuelidate/validators";
import { Options, Vue } from "vue-class-component";
import FormInput from "@/shared/components/FormInput.vue";
import FormCheckbox from "@/shared/components/FormCheckbox.vue";

@Options({
  data() {
    return {
      v$: useVuelidate(),
      formSubmitted: false,
      email: "",
      password: "",
    };
  },
  components: {
    FormInput,
    FormCheckbox,
  },
  validations() {
    return {
      email: { required, email },
      password: { required },
    };
  },
  computed: {
    showFormSelect() {
      return typeof localStorage !== 'undefined' && localStorage.getItem('wem_locale') === 'sk';
    }
  },
  methods: {
    submitForm() {
      var params = new URLSearchParams();
      params.append("email", this.email);
      params.append("password", this.password);
      this.v$.$validate();
      this.formSubmitted = true;
      if (!this.v$.$invalid) {
        this.$store.dispatch("onboarding/makeLogin", {
          params,
        });
      }
    },
  },
})
export default class LoginForm extends Vue {}
